import { CardOnChange } from "../Events/CardOnChange";
import { Styles } from "../Interfacies/Styles";
import { getDefaultStyles } from "../defaultStyles";
import { CardOnValid } from "../Events/CardOnValid";
import { CardOnInValid } from "../Events/CardOnInValid";
import {JSEncrypt} from 'jsencrypt';
import {getPublicKey, getPrivateKey} from '../public_key';
import {Element} from './Element';
import {Card} from './Card';

export class CardExpiryElement extends Element{
    public card: Card;
    private encrypt : JSEncrypt;
    // private  element :  HTMLIFrameElement;
    private url = 'https://elements.flospay.net';//'http://127.0.0.1:8002';//'https://iframe.test';
    // private api_key = '';
    // private styles:Styles =  null;
    // private defaultStyles:Styles =  null;
    private selector =  '';
    // private card_data : string | false;
    private source_origin : string;
    private referer_origin : string;
    constructor(_api_key:string , options?:ElementOptions){
        super(_api_key , 'card', options);
        this.styles = options.style;
        // this.api_key = _api_key;
        this.source_origin = window.location.origin;
        this.referer_origin = document.referrer;
        this.defaultStyles = getDefaultStyles();
        if (!this.styles) {
            this.styles = this.defaultStyles;
        }
        if (!this.styles.type) {
            this.styles.type = this.defaultStyles.type;
        }
        this.element  = this.createIframe();
        this.setPublicKey();

    }
    mount(selector:string){
        
        this.selector = selector;
        document.querySelector(selector).appendChild(this.element.getRootNode());
        const a :any = document.getElementById('ifrm');

        a.onload = ()=>{
           window.onmessage  =  (ev)=>{
               try {
                    const da = JSON.parse(ev.data.data);
                    console.log(da.event);
                    if(da.event == 'onValid'){
                        var data = ((JSON.stringify({
                            number  : da.card.number,
                            cvv  : da.card.cvc,
                            month  : da.card.expMonth,
                            year  : da.card.expYear,
                            postalCode  : da.card.postalCode,
                        })));
                        

                        const sd : string | false = this.encrypt.encrypt(data);
                        this.card_data = sd;


                        this.createInputHiddenElement(selector  ,sd);
                    
                    } else {
                        this.removeInputHiddenElement();
                    }
                    // console.log('EVVV' , da.event);
               } catch (error) {
                //    console.log('Hoso Moso',ev.data); 
               }
           }
        }
       

    }

    getCardData(){
        return this.card_data;
    }


    createInputHiddenElement(selector , value=null){
        let cardInput : HTMLInputElement;
        console.log(selector+" #flospay-card");
        if(!!document.getElementById("flospay-card")){
            cardInput   = (document.getElementById('flospay-card')  as HTMLInputElement);
            cardInput.value = value;
            return ;
        }
        cardInput   = document.createElement('input');
        cardInput.type = 'hidden';
        cardInput.name = 'card';
        cardInput.id = 'flospay-card';
        cardInput.value = value;
        document.querySelector(selector).appendChild(cardInput.getRootNode());
        
    }

    removeInputHiddenElement(){

    }

    on(event:string , callback:Function ){
        if(event == 'change'){
            return new CardOnChange(event ,this.api_key  , callback  ,  this.element);
        }
        if(event == 'valid'){
            return new CardOnValid(event ,this.api_key  , callback  ,  this.element , this.selector);
        }
        if(event == 'invalid'){
            return new CardOnInValid(event ,this.api_key  , callback  ,  this.element);
        }
    }

    setPublicKey(){
        // console.log('Hisham')
        this.encrypt = new JSEncrypt({
            // log:true,
        });
        
        this.encrypt.setPublicKey(getPublicKey());
        
        
    }
    
    createIframe(){
        const defaultStyles = getDefaultStyles();
        const element : HTMLIFrameElement = document.createElement("iframe");
        element.src = this.url+this.getIframeUrlQueryStr();
        element.id = 'ifrm'
        element.frameBorder = "0";
        element.scrolling = "no";
        element.setAttribute('style' , 
            `border: none;
            height:${this.styles.height ||  this.styles.type == 'inline'? defaultStyles.height : (this.styles.type.toLowerCase() == 'twolines'?defaultStyles.twoLinesHeight:'87px')};
            !important; 
            margin: 0px !important; 
            padding: 0px !important; width: 1px !important; min-width: 100% !important; 
            overflow: hidden !important; display: block !important; user-select: none !important; will-change: transform !important;
            `);
        // element.allow =  "*";
        element.allowPaymentRequest = true;
        
        return element;
    }

    setTypeOfInput(){
        if(this.styles && this.styles.type){
            return `?type=${this.styles.type}&parenturl=${window.location.href}`;
        }else{
            return `?type=${this.defaultStyles.type}&parenturl=${window.location.href}`;
        }
    }
    getIframeUrlQueryStr(){
        const parenturl =  encodeURIComponent(window.location.href);
        if(this.styles.type){
            return `?type=${this.styles.type}&api_pkey=${this.api_key}&parenturl=${parenturl}`;
        }else{
            return `?type=${this.defaultStyles.type}&api_pkey=${this.api_key}&parenturl=${parenturl}`;
        }
    }
    getIframeUrlQueryStr2(){
        if(this.styles.type){
            return `?type=${this.styles.type}&api_pkey=${this.api_key}&parenturl=${window.location.href}&source_origin=${this.source_origin}&referer_origin=${this.referer_origin}`;
        }else{
            return `?type=${this.defaultStyles.type}&api_pkey=${this.api_key}&parenturl=${window.location.href}&source_origin=${this.source_origin}&referer_origin=${this.referer_origin}`;
        }
    }

}
// import { ThreeDSecure } from "~packages/ThreeDSecure";
import { ThreeDSecure } from "./packages/ThreeDSecure";
import { Elements } from "./packages/Elements";
import { Card } from "./packages/Card";
import { Element } from "./packages/Element";
import { ISourceData, ICustomerData } from './Interfacies/ISourceData';
import {RedirectToCheckoutOptions} from './types/paayes-js/checkout';
import * as api from './types/api';
import * as paymentIntents from './types/paayes-js/payment-intents';
import * as setupIntents from './types/paayes-js/setup-intents';
// import * as tokens from './types/paayes-js/token-and-sources';
import * as elements from './types/paayes-js/elements';

import {PaayesElements, PaayesElementsOptions} from './types/paayes-js/elements-group';
import {PaymentRequestOptions, PaymentRequest} from './types/paayes-js/payment-request';
import {PaayesElement, PaayesElementLocale} from './types/paayes-js/elements-group';
import {CheckoutLocale} from './types/paayes-js/checkout';
import { Paayes } from "~types/paayes-js/stripe";
import { Token } from "./types/api";
// import { PaymentIntent, Source } from "./types/api";

class  Main implements Paayes{
    private  api_key: string;
    private  clientSecret: string;
    private  apiBaseUri:string= "http://127.0.0.1:8000"; //'https://api.flospay.net';
    private  customerData:ICustomerData;
    public threeDSecure: ThreeDSecure;
    private paymentIntent: api.PaymentIntent | PaymentIntentResult;
    private source: api.Source | SourceResult;
    private token: api.Token | TokenResult;
    private reject: Function;
    private resolve: Function;



    constructor(_api_token:string, options?:PaayesConstructorOptions){
        this.api_key = _api_token;
        this.customerData = {email:'hom@bom.dom'};
        this.threeDSecure = new ThreeDSecure(this.api_key);
        
    }
    /**
     * This method creates an Elements instance, which manages a group of elements.
     * @param options A set of options to create this Elements instance with. {fonts,locale}
     * @returns Elements
     */
     elements(options?: PaayesElementsOptions): PaayesElements | Elements {
        console.log(this.customerData);
        if (options && options.clientSecret) {
          
          this.clientSecret = options.clientSecret;
        }
        return new Elements(this.api_key);

    }

     async getBrowserFingerprint(){
      var wnavlist = {};
      var prop: string;
      for(prop in window.navigator){
        if(prop=='requestMediaKeySystemAccess'){
        continue;
        }
        if('function'== typeof window.navigator[prop]){
          try{
            if(window.navigator[prop]() instanceof Promise){
              wnavlist[prop] = await window.navigator[prop]().then((res)=>res)
              .catch((err)=>err);
            }else{
              wnavlist[prop] = await window.navigator[prop]();
            }
          }catch(err){
          // console.log(err);
          }
        
        }else{
          wnavlist[prop] = window.navigator[prop];
        }
      }   
      // return wnavlist;  
      
      var wscreenlist = {};
      for(prop in window.screen){
        if(prop=='requestMediaKeySystemAccess'){
          continue;
        }
        if('function'== typeof window.screen[prop]){
          try{
            if(window.screen[prop]() instanceof Promise){
              wscreenlist[prop] = await window.screen[prop]().then((res)=>res)
              .catch((err)=>err);
            }else{
              wscreenlist[prop] = window.screen[prop]();
            }
          }catch(err){
            // console.log(err);
          }
        
        }else{
          wscreenlist[prop] = window.screen[prop];
        }
      }      

      return {timezone_offset: new Date().getTimezoneOffset().toString(), navigator: wnavlist, screen: wscreenlist};
    }
  /////////////////////////////
  /// Elements
  ///
  /// https://paayes.com/docs/js/elements_object
  /////////////////////////////

  /**
   * Create an `Elements` instance, which manages a group of elements.
   */

   /////////////////////////////
   /// Checkout
   ///
   /// https://paayes.com/docs/js/checkout
   /////////////////////////////
 
    /**
     * Use `paayes.redirectToCheckout` to redirect your customers to [Checkout](https://paayes.com/docs/payments/checkout), a Paayes-hosted page to securely collect payment information.
     * When the customer completes their purchase, they are redirected back to your website.
     */
  redirectToCheckout(
      options: RedirectToCheckoutOptions
  ): Promise<never | {error: PaayesError} > {
      return new  Promise<never | {error: PaayesError}>(async (resolve,reject)=>{
        let result = null;
        if(options.sessionId){
            try {
              console.log('this.api_key: ',this.api_key);
              
                result = await fetch(this.apiBaseUri+'/api/v1/sessions/'+options.sessionId,{
                    method : 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization' : `Basic ${this.api_key}`
                        'Authorization' : `Basic ${this.api_key}`
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    // body : JSON.stringify({clientSecret:clientSecret, options})
                }) //.then(res => res.json())
                .then(res => {
                  if (res.status>=200 && res.status<300) {
                    return res.json();
                  } else {
                    // throw new Error("Not Found - retrieved source failed");
                    
                    try {
                      return res.json();
                    } catch (err) {
                      return {error:"Not Found - retrieved source failed"};
                    }
                    
                  }
                  
                })
                .then((result) => {
                  if(result.error){
                    throw result;
                  }else{
                    window.location.href= "https:/checkout.flospay.com/checkout/"+options.sessionId;
                  }
                  resolve(result);
                  console.log('in-sdk:success retrieved source: ', result);
                })
                .catch((err) => {reject(err);console.log('in-sdk:fail retrieved source: ', err);});
                
                resolve(result)
                
                // if(result.error){
                //   throw result.error;
                // }else{
                //   window.location.href= "https:/checkout.paayes.com/checkout/"+options.sessionId;
                // }
                return result;
            } catch (error) {
              reject({error:"Error connection to server"+error});
                // throw "";
                return error;
            }
        } else {
          reject({error:"no data sent"});
            // throw "no data sent";
            // return result;
        }
      });
  
    }
    
    async redirectToCheckoutPage(
        options: RedirectToCheckoutOptions
    ): Promise<never | {error: PaayesError}  | Main | any> {

      let result   = null;
        if(options.sessionId){
            try {
                result = await fetch(this.apiBaseUri+'/api/v1/sessions/'+options.sessionId,{
                    method : 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : `Basic ${this.api_key}`
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    // body : JSON.stringify({clientSecret:clientSecret, options})
                }).then(res => res.json())
                
                if(result.error){
                  throw result.error();
                  
                }
                if (options.handleActions) {
                    window.location = data.return_url;
                } else {
                  window.location.href= "https:/checkout.paayes.com/checkout/"+options.sessionId;

                }
                
                return result;
            } catch (error) {
                throw "Error connection to server";
                // return result;
                
            }
        } else {
            
            throw "no data sent";
            // return result;
        }
        return null;
    }

    async createCustomer(data_customer: ICustomerData){
        let result = null;
        if(data_customer){
            try {
                result = await fetch(this.apiBaseUri+'/api/customer',{
                    method : 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : `Basic ${this.api_key}`
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body : JSON.stringify(data_customer)
                }).then(res => res.json())
                return result;
            } catch (error) {
                // console.log(error);
                return  {response : {data : error}};
                // return result;
                
            }
        } else {
            
            throw "no data sent";
            // return result;
        }

    }

    async createCustomerSources(customer_id: string, data_source: ISourceData){
        let result = null;
        if(data_source.card){
            try {
                result = await fetch(this.apiBaseUri+'/api/customer/'+customer_id+'/sources',{
                    method : 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : `Basic ${this.api_key}`
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body : JSON.stringify(data_source)
                }).then(res => res.json());
                console.log('createCustomerSources:',result);

                this.source=result;
                if (result.object=="source") {
                  this.source =await this.create3DSecure(this.source);
                  return {source:this.source};  
                } else {
                  return {error:result};  
                }
            } catch (error) {
                // return result;
                if (result) {
                  return {error:result};

                } else {
                    throw error;//"Error connection to server";

                }
            }
        } else {
            
            throw "no data sent";
            // return result;
        }

    }

    async createDataSource(data_source: ISourceData){
        // 
        let result = null;
        if(data_source.card){
            try {
                result = await fetch(this.apiBaseUri+'/api/v1/sources',{
                    method : 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : `Basic ${this.api_key}`
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body : JSON.stringify(data_source)
                }).then(res => res.json());
                this.source = result;
                result = await this.create3DSecure(result.source).then((tdsresult:SourceResult)=>{
                  return tdsresult;
                }).catch((tdserror:SourceResult|PaayesError|never)=>{
                  return tdserror;
                });
                return result;
            } catch (error) {
                throw "Error connection to server";
                // return result;
                
            }
        } else {
            
            throw "no data sent";
            // return result;
        }
    }

    async createSource(element?: Element|string|PaayesElement, data?:ISourceData | tokens.CreateSourceData){
        // 
        // console.log(element);
        
        let result = null;
        if ('undefined' == typeof element) {
            if ('undefined' != typeof data && data) {
                // const sourceType = data.type;
                var sourcedata = data??{};
                // sourcedata['type'] = sourceType;
                // sourcedata[sourceType] = data;
        
                return this.createDataSource(sourcedata);
    
            } else {
                throw "Error the element or source data is required";
            }
        }

        if ('string'==typeof element ) {
            const sourceType = element;
            let sourcedata:any = {};
            sourcedata['type'] = sourceType;
            sourcedata[sourceType] = data;
    
            return this.createDataSource(sourcedata);

        } else if( element instanceof Element  || element instanceof Card){

          console.log('elemnt data',element.getData());
          const elementType = element.getType();
          var elementdata = data || {type:elementType};
          elementdata['type'] = elementType;
          elementdata[elementType] = element.getData();
  
          return this.createDataSource(elementdata);



        } else {
            
            console.log('elemnt data',element.getData());
            const elementType = element.getType();
            var elementdata = data;
            elementdata['type'] = elementType;
            elementdata[elementType] = element.getData();
    
            return this.createDataSource(elementdata);
        }
    }

    async createToken(element: Element, data?:any){
        // 
        let result = null;
        console.log('elemnt data',element.getData());
        const elementType = element.getType();
        var elementdata = data;
        elementdata['type'] = elementType;
        elementdata[elementType] = element.getData();

        if(element.getData()){
            try {
                result = await fetch(this.apiBaseUri+'/api/token',{
                    method : 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : `Basic ${this.api_key}`
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body : JSON.stringify(elementdata)
                }).then(res => res.json())
                return result;
            } catch (error) {
                throw "Error connection to server";
                // return result;
                
            }
        } else {
            
            throw "no data sent";
            // return result;
        }
    }






    /**
     * confirmCardPayment after fill payment data
     * @param clientSecret 
     * @param data 
     * @param options 
     * @returns Promise<any | PaymentIntentResult>
     */
    async confirmCardPayment(clientSecret,data?,options?): Promise<any | PaymentIntentResult>{
        
        let result = null;
        let paymentMethodSource = null;
            try {
              if(data){

                if ('string' === typeof data.payment_method) {

                  
                } else if(data.payment_method.card){
                  if (data.payment_method.card.token) {
                    
                  // } else if(Element == typeof data.payment_method.card instanceof ){
                  } else if( data.payment_method.card instanceof Element || data.payment_method.card instanceof Card){
                    paymentMethodSource = await this.createSource( data.payment_method.card);
                    data.payment_method['card'] = paymentMethodSource.card.id;
                    data.payment_method['id'] = paymentMethodSource.id;
                    console.log('paymentMethodSource:',paymentMethodSource);

                    // console.log('elemnt data',data.payment_method.card.getData());
                    // const elementType = data.payment_method.card.getType();
                    // var elementdata = data;
                    data.payment_method['type'] = 'card';//elementType;
                    // data.payment_method[elementType] = data.payment_method.card.getData();
                  }

                }else{

                }
              } else {

              }

              // const paymentMethodSource = await this.createSource('card', data.payment_method.card);
              console.log('paymentMethodSource:',paymentMethodSource);

              if (paymentMethodSource.error) {
                
              }else if(paymentMethodSource){
                console.log('paymentMethodSource:',paymentMethodSource);
                data.payment_method['card'] = paymentMethodSource.card.id;
                data.payment_method['id'] = paymentMethodSource.id;
              }

              data['browser'] = this.getBrowserFingerprint();

              console.log('confirmCardPayment.data:',data);
              

              


  
              

              return new Promise<any | PaymentIntentResult>(async(resolve,reject)=>{
                this.resolve = resolve;
                this.reject = reject;
                
                result =  await fetch(this.apiBaseUri+'/api/v1/payment_intents/'+clientSecret+'/confirm',{
                    method : 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : `Basic ${this.api_key}`
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body : JSON.stringify(data)
                    // body : JSON.stringify({client_secret:clientSecret, data:data, options, _method:'post'})
                })
                .then(async res => {
                  if (res.status>=200 && res.status<300) {
                    return res.json();
                  } else if (res.headers.get('Content-Type').indexOf('json')>=0) {
                    return res.json();
                  } else if (res.headers.get('Content-Type').indexOf('html')>=0) {
                    // reject({ error: "Not Found"});
                    return  { error: "Not Found"};
                    // throw new Error("Not Found - retrieved source failed");
                    
                    return res.text();
                  } else {
                    console.log(res);
                    console.log(res.headers.get('Content-Type'));
                    console.log(res.status);
                    console.log(res.statusText);
                    console.log(res.type);
                    console.log(res.url);
                
                    const utf8Decoder = new TextDecoder('utf-8');
                    const reader = res.body.getReader();
                    let { value: chunk, done: readerDone } = await reader.read();
                    chunk = chunk ? utf8Decoder.decode(chunk) : '';
                    // console.log(readerDone,chunk);

                    return  { error: "Not Found"};



                    
                    // throw new Error("Not Found - retrieved source failed");
                    
                    try {
                      //return res.json();
                    } catch (err) {
                      return  {response : {data : err}};

                      return {error:"Not Found - retrieved source failed"};
                    }
                    
                  }

                })
                .then(  (paymentIntent)=>{

                  if(paymentIntent.error){
                    reject(paymentIntent);
  
                  }else{
                    this.paymentIntent = paymentIntent;
                  }

                  if (paymentIntent.status=='succeeded') {
                    resolve({paymentIntent});

                  } else if (paymentIntent.status=='requires_payment_method') {
                    // reject(paymentIntent);
                    resolve({paymentIntent});

                  } else if (paymentIntent.status=='requires_action') {

                    if (options && ('undefined'!= typeof options.handleActions) &&  (!(options.handleActions))) {
                      //
                      resolve({paymentIntent});

                    }else{
                      if (paymentIntent.next_action.type=='redirect_to_url') {
                        if (paymentIntent.next_action.redirect_to_url) {
                          // next_action.redirect_to_url.url
                          // next_action.redirect_to_url.return_url
                          // top.location = paymentIntent.redirect_to_url.url;
  
                          //create 3dsecure iframe
                          var tdsContainer = document.getElementById('tdscontainer-'+paymentIntent.id+'');
                          // if(!tdsContainer){
                          //   tdsContainer = document.createElement('div');
                          //   tdsContainer.id='tdscontainer-'+paymentIntent.id+'';
                          // }
                          // document.body.append(tdsContainer);
                          var iframe = document.createElement('iframe');
                          // iframe.src = paymentIntent.next_action.redirect_to_url.url;
                          // iframe.id = 'paayes3dsiframe-'+paymentIntent.id+'';
                          // iframe.width = '400';
                          // iframe.height = '600';
                          // tdsContainer.appendChild(iframe);
                          // document.body.append(tdsContainer);

                          //  document.body.append("<div id=\"threedsmodal-${paymentIntent.id}\" class=\"threedsmodal\"> "+
                          //   "<div class=\"threedsmodal-content\"> "+
                          //    " <span class=\"close\">&times;</span>"+
                          //    " <div class=\"threedsmodal-body\">"+
                          //    "  <iframe id=\"paayes3dsiframe-${paymentIntent.id}\" src=\"${paymentIntent.next_action.redirect_to_url.url}\" width=\"400\" height=\"600\"></iframe>"+
                          //    " </div>"+
                          //    " </div>"+
                          //    "</div>");

                          var threedsmodalcntr = document.createElement("div");
                          threedsmodalcntr.id= `threedsmodal-${paymentIntent.id}`;
                          threedsmodalcntr.classList.add('threedsmodal');
                          threedsmodalcntr.innerHTML=  `<div class="threedsmodal-content"> 
                                                      <span class="close">&times;</span>
                                                      <div class="threedsmodal-body">
                                                       <iframe id="paayes3dsiframe-${paymentIntent.id}" src="${paymentIntent.next_action.redirect_to_url.url}" width="400" height="600"></iframe>
                                                      </div>
                                                      </div>`;
                          document.body.appendChild(threedsmodalcntr);
                          var threedsmodalstyle = document.createElement("style");

                          threedsmodalstyle.innerHTML = `
                          
                          /* The threedsmodal (background) */
                          .threedsmodal {
                            // display: none; /* Hidden by default */
                            position: fixed; /* Stay in place */
                            z-index: 1; /* Sit on top */
                            padding-top: 100px; /* Location of the box */
                            left: 0;
                            top: 0;
                            width: 100%; /* Full width */
                            height: 100%; /* Full height */
                            overflow: auto; /* Enable scroll if needed */
                            background-color: rgb(0,0,0); /* Fallback color */
                            background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
                          }
                          
                          /* threedsmodal Content */
                          .threedsmodal-content {
                            background-color: #fefefe;
                            margin: auto;
                            padding: 0px;
                            border: 1px solid #888;
                            width: 405px; /* Full width */
                            height: 620px; /* Full height */
                          }
                          /* threedsmodal Content */
                          .threedsmodal-content {
                            position: relative;
                            background-color: #fefefe;
                            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
                            -webkit-animation-name: animatetop;
                            -webkit-animation-duration: 0.4s;
                            animation-name: animatetop;
                            animation-duration: 0.4s
                          }
                          .threedsmodal-content iframe{
                            border: none;
                          }
                          
                          /* The Close Button */
                          .close {
                            color: #aaaaaa;
                            float: right;
                            font-size: 28px;
                            font-weight: bold;
                            z-index: 1000; /* Sit on top */

                          }
                          
                          .close:hover,
                          .close:focus {
                            color: #000;
                            text-decoration: none;
                            cursor: pointer;
                          }
                          /* Add Animation */
                          @-webkit-keyframes animatetop {
                            from {top:-300px; opacity:0} 
                            to {top:0; opacity:1}
                          }
                          
                          @keyframes animatetop {
                            from {top:-300px; opacity:0}
                            to {top:0; opacity:1}
                          }
                          
                          `;
                          document.head.appendChild(threedsmodalstyle);

                          // setTimeout(() => {
                              
                            // Get the modal
                            var threedsmodal = threedsmodalcntr;//document.getElementById(`threedsmodal-${paymentIntent.id}`);
                            threedsmodal.style.display = "block";

                            // Get the <span> element that closes the modal
                            var tdsmodelclose = document.getElementsByClassName("close")[0];
                                                    
                            // When the user clicks on <span> (x), close the modal
                            tdsmodelclose.addEventListener('click',(ev)=>{
                              threedsmodal.style.display = "none";
                              threedsmodal.remove();
                              reject(paymentIntent);
                              // resolve(paymentIntent);
                            });
                            
                            // When the user clicks anywhere outside of the modal, close it
                            window.onclick = function(event) {
                              if (event.target == threedsmodal) {
                              // modal.style.display = "none";
                              }
                            }

                            const thisclass = this;


                            // window.top.postMessage('3DS-authentication-complete');
                          
                            window.addEventListener('message', this.on3DSComplete,false);
                            
                          // }, 200);

                        
                          // this.createThreeDSecure(clientSecret,paymentIntent)
                          // .then(async(authres:any)=>{
                          //     if (authres.success) {
                          //       const customer = await this.createCustomer(data.payment_method.billing_details);
                          //       this.createCustomerSources(customer.id,data.payment_method.card);
                          //     } else {
                                
                          //     }
                          // });
  
                        } else {
                          resolve({paymentIntent});
                        }
                      } else {
                        resolve({paymentIntent});
                      }
                        // window.location = data.return_url;
                    }

                  } else {
                    resolve({paymentIntent});
                  }
  
                }).catch((error)=>{
                  reject(error);

                });
                
              });
                
                return result;
            } catch (error) {
              return  new Promise<any | PaymentIntentResult>((resolve,reject)=>{
                  reject({error});
                // throw "Error connection to server";
                // return result;
              });
                
            }
    }

    async createThreeDSecure(clientSecret,data?:IElement|any,options?){
        return new ThreeDSecure(this.api_key,'card',clientSecret, data, options);
    }


    // {tdscontainer:string|HTMLDivElement,source:Source,options?:any }
    async create3DSecure(source:Source|tokens.RetrieveSourceParam,options?:any  ): Promise<never | SourceResult> {
      return  new Promise<any | SourceResult>(async (resolve,reject)=>{
        this.resolve = resolve;
        this.reject = reject;

        console.log('create3DSecure-source:', source);
        console.log('create3DSecure-source.clientSecret:', source.client_secret);
        console.log('create3DSecure-thhis.source:', this.source);
        
        // if (keyof tokens.RetrieveSourceParam == typeof source || source instanceof tokens.RetrieveSourceParam) {
        if (source.client_secret){// || source instanceof tokens.RetrieveSourceParam) {
          source = await this.retrieveSource({id:this.source.id,client_secret:this.source.client_secret});
          this.source = source;
          // .then(function(result) {
          //   if (result.error) {
          //     this.reject(result);
          //     // source client secret was invalid
          //   } else {
          //     source = result;
          //     if (result.source.status === 'succeeded') {
          //       // Show your customer that the payment has succeeded
          //       this.resolve(result);
          //     } else if (result.source.status === 'requires_payment_method') {
          //       this.reject(result);
          //       // Authentication failed, prompt the customer to enter another payment method
          //     }else{
          //       this.resolve(result);
          //     }
          //   }
          // });
            
        } else {
          
        }

        console.log('create3DSecure-thhis.source:', this.source);
        console.log('create3DSecure-thhis.source.clientSecret:', this.source.client_secret);



      if (source.flow=='redirect') {
        if (source.redirect) {
          // next_action.redirect_to_url.url
          // next_action.redirect_to_url.return_url
          // top.location = source.redirect_to_url.url;

          //create 3dsecure iframe
          var tdsContainer = document.getElementById('tdscontainer-'+source.id+'');
          // if(!tdsContainer){
          //   tdsContainer = document.createElement('div');
          //   tdsContainer.id='tdscontainer-'+source.id+'';
          // }
          // document.body.append(tdsContainer);
          var iframe = document.createElement('iframe');
          // iframe.src = source.next_action.redirect_to_url.url;
          // iframe.id = 'paayes3dsiframe-'+source.id+'';
          // iframe.width = '400';
          // iframe.height = '600';
          // tdsContainer.appendChild(iframe);
          // document.body.append(tdsContainer);

          //  document.body.append("<div id=\"threedsmodal-${source.id}\" class=\"threedsmodal\"> "+
          //   "<div class=\"threedsmodal-content\"> "+
          //    " <span class=\"close\">&times;</span>"+
          //    " <div class=\"threedsmodal-body\">"+
          //    "  <iframe id=\"paayes3dsiframe-${source.id}\" src=\"${source.next_action.redirect_to_url.url}\" width=\"400\" height=\"600\"></iframe>"+
          //    " </div>"+
          //    " </div>"+
          //    "</div>");

          var threedsmodalcntr = document.createElement("div");
          threedsmodalcntr.id= `threedsmodal-${source.id}`;
          threedsmodalcntr.classList.add('threedsmodal');
          threedsmodalcntr.innerHTML=  `<div class="threedsmodal-content"> 
                                      <span class="close">&times;</span>
                                      <div class="threedsmodal-body">
                                       <iframe id="paayes3dsiframe-${source.id}" src="${source.redirect.url}" width="400" height="600"></iframe>
                                      </div>
                                      </div>`;
          document.body.appendChild(threedsmodalcntr);
          var threedsmodalstyle = document.createElement("style");

          threedsmodalstyle.innerHTML = `
          
          /* The threedsmodal (background) */
          .threedsmodal {
            // display: none; /* Hidden by default */
            position: fixed; /* Stay in place */
            z-index: 1; /* Sit on top */
            padding-top: 100px; /* Location of the box */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: rgb(0,0,0); /* Fallback color */
            background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
          }
          
          /* threedsmodal Content */
          .threedsmodal-content {
            background-color: #fefefe;
            margin: auto;
            padding: 0px;
            border: 1px solid #888;
            width: 405px; /* Full width */
            height: 620px; /* Full height */
          }
          /* threedsmodal Content */
          .threedsmodal-content {
            position: relative;
            background-color: #fefefe;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
            -webkit-animation-name: animatetop;
            -webkit-animation-duration: 0.4s;
            animation-name: animatetop;
            animation-duration: 0.4s
          }
          .threedsmodal-content iframe{
            border: none;
          }
          
          /* The Close Button */
          .close {
            color: #aaaaaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
            z-index: 1000; /* Sit on top */

          }
          
          .close:hover,
          .close:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
          }
          /* Add Animation */
          @-webkit-keyframes animatetop {
            from {top:-300px; opacity:0} 
            to {top:0; opacity:1}
          }
          
          @keyframes animatetop {
            from {top:-300px; opacity:0}
            to {top:0; opacity:1}
          }
          
          `;
          document.head.appendChild(threedsmodalstyle);

          // setTimeout(() => {
              
            // Get the modal
            var threedsmodal = threedsmodalcntr;//document.getElementById(`threedsmodal-${source.id}`);
            threedsmodal.style.display = "block";

            // Get the <span> element that closes the modal
            var tdsmodelclose = document.getElementsByClassName("close")[0];
                                    
            // When the user clicks on <span> (x), close the modal
            tdsmodelclose.addEventListener('click',(ev)=>{
              threedsmodal.style.display = "none";
              threedsmodal.remove();
              reject(source);
              // resolve(source);
            });
            
            // When the user clicks anywhere outside of the modal, close it
            window.onclick = function(event) {
              if (event.target == threedsmodal) {
              // modal.style.display = "none";
              }
            }

            const thisclass = this;


            // window.top.postMessage('3DS-authentication-complete');
          
            window.addEventListener('message', this.onSource3DSComplete.bind(this,resolve,reject), false);
            
          // }, 200);

        
          // this.createThreeDSecure(clientSecret,source)
          // .then(async(authres:any)=>{
          //     if (authres.success) {
          //       const customer = await this.createCustomer(data.payment_method.billing_details);
          //       this.createCustomerSources(customer.id,data.payment_method.card);
          //     } else {
                
          //     }
          // });

        } else {
          resolve(source);
        }
      } else {
        resolve(source);
      }

    });

    }



    onSource3DSComplete(  resolve,reject,ev: MessageEvent<any>){

      console.log('onSource3DSComplete:  this, this.source: ',this, this.source);
      console.log('onSource3DSComplete: this.options: ',this.options);
      console.log('onSource3DSComplete: resolve,reject: ',resolve,reject);
      console.log('onSource3DSComplete-window.addEventListener: onSource3DSComplete: ',ev, ev.data);
      window.removeEventListener("message", this.onSource3DSComplete, false);
      var threedsmodal = document.getElementById(`threedsmodal-${this.source.id}`) ;//|| document.getElementsByClassName('threedsmodal')[0] ;

      if (ev.data === '3DS-authentication-complete') {
      // Hide the 3DS UI
      threedsmodal.style.display = "none";
      threedsmodal.remove();
      // var modal = document.getElementById(`threedsmodal-${source.id}`);


      // Check the source
      this.retrieveSource({id:this.source.id,client_secret:this.source.client_secret})
        .then(function(result) {
          if (result.error) {
            reject(result);
            // source client secret was invalid
          } else {
            this.source= result;
            if (this.source.status === 'succeeded') {
              // Show your customer that the payment has succeeded
              resolve(this.source);
            } else if (this.source.status === 'requires_payment_method') {
              // this.reject(this.source);
              // Authentication failed, prompt the customer to enter another payment method
            }else{
              resolve(this.source);
            }
          }
        }).catch((err)=>{
          reject(err);
        });


      }
    }

    on3DSComplete( ev: MessageEvent<any> ){

      console.log('window.addEventListener: ',ev, ev.data);
      window.removeEventListener("message", this.on3DSComplete, false);
      var threedsmodal = document.getElementById(`threedsmodal-${this.paymentIntent.id}`) ;//|| document.getElementsByClassName('threedsmodal')[0] ;

      if (ev.data === '3DS-authentication-complete') {
      // Hide the 3DS UI
      threedsmodal.style.display = "none";
      threedsmodal.remove();
      // var modal = document.getElementById(`threedsmodal-${paymentIntent.id}`);


      // Check the PaymentIntent
      this.retrievePaymentIntent(this.paymentIntent.clientSecret)
        .then(function(result) {
          if (result.error) {
            this.reject(result);
            // PaymentIntent client secret was invalid
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show your customer that the payment has succeeded
              this.resolve(result);
            } else if (result.paymentIntent.status === 'requires_payment_method') {
              this.reject(result);
              // Authentication failed, prompt the customer to enter another payment method
            }else{
              this.resolve(result);
            }
          }
        });


      }
    }

  /////////////////////////////
  /// Payment Intents
  ///
  /// https://paayes.com/docs/js/payment_intents
  /////////////////////////////

  /**
   * Use `paayes.confirmPayment` to confirm a PaymentIntent using data collected by the [Payment Element](https://paayes.com/docs/js/element/payment_element).
   * When called, `paayes.confirmPayment` will attempt to complete any [required actions](https://paayes.com/docs/payments/intents), such as authenticating your user by displaying a 3DS dialog or redirecting them to a bank authorization page.
   * Your user will be redirected to the return_url you pass once the confirmation is complete.
   *
   * By default, paayes.`confirmPayment` will always redirect to your return_url after a successful confirmation.
   * If you set `redirect: "if_required"`, then `paayes.confirmPayment` will only redirect if your user chooses a redirect-based payment method.
   * Setting `if_required` requires that you handle successful confirmations for redirect-based and non-redirect based payment methods separately.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_payment
   */
   confirmPayment(options: {
    elements: PaayesElements;
    confirmParams?: Partial<paymentIntents.ConfirmPaymentData>;
    redirect: 'if_required';
  }): Promise<PaymentIntentResult>;

  /**
   * Use `paayes.confirmPayment` to confirm a PaymentIntent using data collected by the [Payment Element](https://paayes.com/docs/js/element/payment_element).
   * When called, `paayes.confirmPayment` will attempt to complete any [required actions](https://paayes.com/docs/payments/intents), such as authenticating your user by displaying a 3DS dialog or redirecting them to a bank authorization page.
   * Your user will be redirected to the return_url you pass once the confirmation is complete.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_payment
   */
  confirmPayment(options: {
    elements: PaayesElements;
    confirmParams: paymentIntents.ConfirmPaymentData;
    redirect?: 'always';
  }): Promise<never | {error: PaayesError}>;


  confirmPayment(options: {
    clientSecret: string;
    elements: PaayesElements;
    confirmParams: paymentIntents.ConfirmPaymentData;
    redirect?: 'always' | 'if_required';
  }): Promise<never | PaymentIntentResult | {error: PaayesError}>{


    return new  Promise<never | PaymentIntentResult | {error: PaayesError}>(async (resolve,reject)=>{
      let result = null;
      let confirmParams:paymentIntents.ConfirmPaymentData & {clientSecret: string}  =  options.confirmParams;
      confirmParams.clientSecret = options.clientSecret ? options.clientSecret: this.clientSecret;
      
      if(options.elements){
          try {
              result = await fetch(this.apiBaseUri+'/api/v1/payment_intents/confirm_payment/'+(options.clientSecret?options.clientSecret:this.clientSecret),{
                  method : 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization' : `Basic ${this.api_key}`
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body : JSON.stringify(options.confirmParams)
              })
              .then(res => {
                if (res.status>=200 && res.status<300) {
                  return res.json();
                } else {
                  throw new Error("Not Found - confirmPayment failed");
                  
                  try {
                    return res.headers.has;
                  } catch (err) {
                    return {error:"Not Found - confirmPayment failed"};
                  }
                  
                }
                
              })
              .then((result:PaymentIntentResult) => {resolve(result);console.log('in-sdk:success confirmPayment: ', result);})
              .catch((err) => {reject(err);console.log('in-sdk:fail confirmPayment: ', err);});
              resolve(result)
              return result;
          } catch (error) {
            reject({error:"no data sent"});
              // throw "Error connection to server";
              return error;
              
          }
      } else {
        reject({error:"no data sent"});
          // throw "no data sent";
          // return result;
      }
    });

  }

  /**
   * Use `paayes.confirmAcssDebitPayment` in the [Accept a Canadian pre-authorized debit payment](https://paayes.com/docs/payments/acss-debit/accept-a-payment) flow when the customer submits your payment form.
   * When called, it will automatically pop up a modal to collect bank account details and verification, accept the mandate, and confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) when the user submits the form.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * `paayes.confirmAcssDebitPayment` automatically creates a new `PaymentMethod` for you when your customer completes the modal UI.
   * It can also be called with an existing `PaymentMethod` which will load the modal UI to collect a new mandate agreement.
   * If you have already attached a `PaymentMethod`, you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_acss_debit_payment
   */
  confirmAcssDebitPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmAcssDebitPaymentData,
    options?: paymentIntents.ConfirmAcssDebitPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmAlipayPayment` in the [Alipay Payments](https://paayes.com/docs/payments/alipay) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_alipay_payment
   */
  confirmAlipayPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmAlipayPaymentData,
    options?: paymentIntents.ConfirmAlipayPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Requires beta access:
   * Contact [Paayes support](https://support.paayes.com/) for more information.
   *
   * Use `paayes.confirmAuBecsDebitPayment` in the [BECS Direct Debit Payments](https://paayes.com/docs/payments/payment-methods/au-becs-debit) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/payment-methods/au-becs-debit-quickstart-payment-intents) for more details.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_au_becs_debit_payment
   */
  confirmAuBecsDebitPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmAuBecsDebitPaymentData
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmBancontactPayment` in the [Bancontact Payments with Payment Methods](https://paayes.com/docs/payments/bancontact#web) flow when the customer submits your payment form.
   * When called, it will confirm the `PaymentIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_bancontact_payment
   */
  confirmBancontactPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmBancontactPaymentData,
    options?: paymentIntents.ConfirmBancontactPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmBoletoPayment` in the [Boleto Payment](https://paayes.com/docs/payments/boleto) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/boleto) for more details.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_boleto_payment
   */
  confirmBoletoPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmBoletoPaymentData,
    options?: paymentIntents.ConfirmBoletoPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmCardPayment` when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide and carry out 3DS or other next actions if they are required.
   *
   * If you are using [Dynamic 3D Secure](https://paayes.com/docs/payments/3d-secure#three-ds-radar), `paayes.confirmCardPayment` will trigger your Radar rules to execute and may open a dialog for your customer to authenticate their payment.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * It can also be called with an existing `PaymentMethod`, or if you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_card_payment
   */
  confirmCardPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmCardPaymentData,
    options?: paymentIntents.ConfirmCardPaymentOptions
  ): Promise<PaymentIntentResult>;

  /**
   * Requires beta access:
   * Contact [Paayes support](https://support.paayes.com/) for more information.
   *
   * Use `paayes.confirmCustomerBalancePayment` when the customer submits your payment form.
   *
   * When called, it will confirm the PaymentIntent with data you provide.
   * Refer to our [integration guide](https://paayes.com/docs/payments/bank-transfers) for more details.
   *
   * Since the Customer Balance payment method draws from a balance, the attempt will succeed or fail depending on the current balance amount. To collect more funds from the customer when the cash balance is insufficient, use the customer balance with bank transfer funding parameters.
   * The confirmation attempt will finish in one of the following result states:
   * 1. If the customer balance was enough to pay the amount, the status is succeeded. The funding_type data is effectively ignored.
   * 2. If the balance was not enough to pay the amount, and you didn't send the funding_type, the status is requires_payment_method.
   * 3. If the balance was not enough to pay the amount, and you did send the funding_type, the status is requires_action. The paymentIntent.next_action.display_bank_transfer_instructions hash contains bank transfer details for funding the Customer Balance.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_customer_balance_payment
   */
  confirmCustomerBalancePayment(
    clientSecret: string,
    data: paymentIntents.ConfirmCustomerBalancePaymentData,
    options: paymentIntents.ConfirmCustomerBalancePaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmEpsPayment` in the [EPS Payments with Payment Methods](https://paayes.com/docs/payments/eps#web) flow when the customer submits your payment form.
   * When called, it will confirm the `PaymentIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_eps_payment
   */
  confirmEpsPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmEpsPaymentData,
    options?: paymentIntents.ConfirmEpsPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmFpxPayment` in the [FPX Payments with Payment Methods](https://paayes.com/docs/payments/fpx#web) flow when the customer submits your payment form.
   * When called, it will confirm the `PaymentIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_fpx_payment
   */
  confirmFpxPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmFpxPaymentData,
    options?: paymentIntents.ConfirmFpxPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmGiropayPayment` in the [giropay Payments with Payment Methods](https://paayes.com/docs/payments/giropay#web) flow when the customer submits your payment form.
   * When called, it will confirm the `PaymentIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_giropay_payment
   */
  confirmGiropayPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmGiropayPaymentData,
    options?: paymentIntents.ConfirmGiropayPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmGrabPayPayment` in the [GrabPay payments](https://paayes.com/docs/payments/grabpay) flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents).
   * Refer to our [integration guide](https://paayes.com/docs/payments/grabpay/accept-a-payment) for more details.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_grabpay_payment
   */

  confirmGrabPayPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmGrabPayPaymentData,
    options?: paymentIntents.ConfirmGrabPayPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmIdealPayment` in the [iDEAL Payments with Payment Methods](https://paayes.com/docs/payments/ideal) flow when the customer submits your payment form.
   * When called, it will confirm the `PaymentIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_ideal_payment
   */
  confirmIdealPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmIdealPaymentData,
    options?: paymentIntents.ConfirmIdealPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Requires beta access:
   * Contact [Paayes support](https://support.paayes.com/) for more information.
   *
   * Use `paayes.confirmKlarnaPayment` in the [Klarna Payments](https://paayes.com/docs/payments/klarna) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_klarna_payment
   */
  confirmKlarnaPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmKlarnaPaymentData,
    options?: paymentIntents.ConfirmKlarnaPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmOxxoPayment` in the [OXXO Payment](https://paayes.com/docs/payments/oxxo) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/oxxo) for more details.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_oxxo_payment
   */
  confirmOxxoPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmOxxoPaymentData,
    options?: paymentIntents.ConfirmOxxoPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmP24Payment` in the [Przelewy24 Payments with Payment Methods](https://paayes.com/docs/payments/p24#web) flow when the customer submits your payment form.
   * When called, it will confirm the `PaymentIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_p24_payment
   */
  confirmP24Payment(
    clientSecret: string,
    data?: paymentIntents.ConfirmP24PaymentData,
    options?: paymentIntents.ConfirmP24PaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Requires beta access:
   * Contact [Paayes support](https://support.paayes.com/) for more information.
   *
   * Use `paayes.confirmPayNowPayment` in the [PayNow Payments](https://paayes.com/docs/payments/paynow) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide.
   * Refer to our [integration guide](https://paayes.com/docs/payments/paynow) for more details.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   */
  confirmPayNowPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmPayNowPaymentData,
    options?: paymentIntents.ConfirmPayNowPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Requires beta access:
   * Contact [Paayes support](https://support.paayes.com/) for more information.
   *
   * Use `paayes.confirmPayPalPayment` in the [PayPal Payments](https://paayes.com/docs/payments/paypal) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_paypal_payment
   */
  confirmPayPalPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmPayPalPaymentData
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Requires beta access:
   * Contact [Paayes support](https://support.paayes.com/) for more information.
   *
   * Use `paayes.confirmPromptPayPayment` in the [PromptPay Payments](https://paayes.com/docs/payments/promptpay) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide.
   * Refer to our [integration guide](https://paayes.com/docs/payments/promptpay) for more details.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   */
  confirmPromptPayPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmPromptPayPaymentData,
    options?: paymentIntents.ConfirmPromptPayPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmSepaDebitPayment` in the [SEPA Direct Debit Payments](https://paayes.com/docs/payments/sepa-debit) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/sepa-debit) for more details.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_sepa_debit_payment
   */
  confirmSepaDebitPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmSepaDebitPaymentData
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmSofortPayment` in the [Sofort Payments with Payment Methods](https://paayes.com/docs/payments/sofort) flow when the customer submits your payment form.
   * When called, it will confirm the `PaymentIntent` with `data` you provide. It will then automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_sofort_payment
   */
  confirmSofortPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmSofortPaymentData
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Requires beta access:
   * Contact [Paayes support](https://support.paayes.com/) for more information.
   *
   * Use `paayes.confirmWechatPayPayment` in the [Wechat Pay Payments](https://paayes.com/docs/payments/wechat-pay) with Payment Methods flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents) with `data` you provide.
   * Refer to our [integration guide](https://paayes.com/docs/payments/wechat-pay/accept-a-payment) for more details.
   *
   * When you confirm a `PaymentIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `PaymentIntent`, this method can automatically create and attach a new PaymentMethod for you.
   * If you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_wechat_pay_payment
   */
  confirmWechatPayPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmWechatPayPaymentData,
    options?: paymentIntents.ConfirmWechatPayPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.handleCardAction` in the Payment Intents API [manual confirmation](https://paayes.com/docs/payments/payment-intents/web-manual) flow to handle a [PaymentIntent](https://paayes.com/docs/api/payment_intents) with the `requires_action` status.
   * It will throw an error if the `PaymentIntent` has a different status.
   *
   * Note that `paayes.handleCardAction` may take several seconds to complete.
   * During that time, you should disable your form from being resubmitted and show a waiting indicator like a spinner.
   * If you receive an error result, you should be sure to show that error to the customer, re-enable the form, and hide the waiting indicator.
   *
   * Additionally, `paayes.handleCardAction` may trigger a [3D Secure](https://paayes.com/docs/payments/3d-secure) authentication challenge.
   * The authentication challenge requires a context switch that can be hard to follow on a screen-reader.
   * Ensure that your form is accessible by ensuring that success or error messages are clearly read out.
   *
   * @docs https://paayes.com/docs/js/payment_intents/handle_card_action
   */
  handleCardAction(clientSecret: string): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.verifyMicrodepositsForPayment` in the [Accept a Canadian pre-authorized debit payment](https://paayes.com/docs/payments/acss-debit/accept-a-payment) flow
   * to verify a customer's bank account with micro-deposits.
   *
   * @docs https://paayes.com/docs/js/payment_intents/verify_microdeposits_for_payment
   */
  verifyMicrodepositsForPayment(
    clientSecret: string,
    data?: paymentIntents.VerifyMicrodepositsForPaymentData
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use paayes.createPaymentMethod to convert payment information collected by elements into a [PaymentMethod](https://paayes.com/docs/api/payment_methods) object that you safely pass to your server to use in an API call.
   *
   * @docs https://paayes.com/docs/js/payment_methods/create_payment_method
   */
  createPaymentMethod(
    paymentMethodData: paymentIntents.CreatePaymentMethodData
  ): Promise<PaymentMethodResult>{

    return null;
}

  /**
   * Retrieve a [PaymentIntent](https://paayes.com/docs/api/payment_intents) using its [client secret](https://paayes.com/docs/api/payment_intents/object#payment_intent_object-client_secret).
   *
   * @docs https://paayes.com/docs/js/payment_intents/retrieve_payment_intent
   */
  retrievePaymentIntent(clientSecret: string): Promise<PaymentIntentResult>{

    return new  Promise<PaymentIntentResult>(async (resolve,reject)=>{
      let result = null;
      // let confirmParams:paymentIntents.ConfirmPaymentData & {clientSecret: string}  =  options.confirmParams;
      // confirmParams.clientSecret = options.clientSecret ? options.clientSecret: this.clientSecret;
      
      if(clientSecret){
          try {
              result = await fetch(this.apiBaseUri+'/api/v1/payment_intents/'+(clientSecret?clientSecret:this.clientSecret),{
                  method : 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization' : `Basic ${this.api_key}`
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  // body : JSON.stringify({clientSecret})
              })
              .then(res => {
                if (res.status>=200 && res.status<300) {
                  return res.json();
                } else {
                  // throw new Error("Not Found - retrieve PaymentIntent failed");
                  
                  try {
                    return res.json();
                  } catch (err) {
                    return {error:"Not Found - retrieve PaymentIntent failed"};
                  }
                  
                }
                
              })
              .then((paymentIntent:PaymentIntentResult) => {
                resolve(paymentIntent);
                console.log('in-sdk:success retrieve PaymentIntent: ', result);
                return paymentIntent;
              })
              .catch((err) => {
                reject(err);
                console.log('in-sdk:fail retrieve PaymentIntent: ', err);
                return err;
              });
              resolve(result)
              return result;
          } catch (error) {
            reject({error});
            return error;
          }
      } else {
        reject({error:"clientSecret required"});
          // throw "no data sent";
        return result;
      }
    });

    
}

  /////////////////////////////
  /// Setup Intents
  ///
  /// https://paayes.com/docs/js/setup_intents
  /////////////////////////////

  /**
   * Use `paayes.confirmSetup` to confirm a SetupIntent using data collected by the [Payment Element](https://paayes.com/docs/js/element/payment_element).
   * When called, `paayes.confirmSetup` will attempt to complete any [required actions](https://paayes.com/docs/payments/intents), such as authenticating your user by displaying a 3DS dialog or redirecting them to a bank authorization page.
   * Your user will be redirected to the return_url you pass once the confirmation is complete.
   *
   * By default, paayes.`confirmSetup` will always redirect to your return_url after a successful confirmation.
   * If you set `redirect: "if_required"`, then `paayes.confirmSetup` will only redirect if your user chooses a redirect-based payment method.
   * Setting `if_required` requires that you handle successful confirmations for redirect-based and non-redirect based payment methods separately.
   *
   * @docs https://paayes.com/docs/js/setup_intents/confirm_setup
   */
  // confirmSetup(options: {
  //   elements: PaayesElements;
  //   confirmParams?: Partial<paymentIntents.ConfirmPaymentData>;
  //   redirect: any | 'if_required' | 'always';
  // }): Promise<SetupIntentResult>;

  /**
   * Use `paayes.confirmSetup` to confirm a SetupIntent using data collected by the [Payment Element](https://paayes.com/docs/js/element/payment_element).
   * When called, `paayes.confirmSetup` will attempt to complete any [required actions](https://paayes.com/docs/payments/intents), such as authenticating your user by displaying a 3DS dialog or redirecting them to a bank authorization page.
   * Your user will be redirected to the return_url you pass once the confirmation is complete.
   *
   * @docs https://paayes.com/docs/js/setup_intents/confirm_setup
   */
  //  confirmSetup(options: {
  //   elements: PaayesElements;
  //   confirmParams: paymentIntents.ConfirmPaymentData;
  //   redirect?: 'if_required' | 'always';
  // }): Promise<never | {error: PaayesError}>;

confirmSetup(options: {
  elements: PaayesElements;
  confirmParams: paymentIntents.ConfirmPaymentData;
  redirect?: any | 'if_required' | 'always';
}): Promise<never | {error: PaayesError}>{

  
  return new Promise<never | {error: PaayesError}>((resolve: () => void, reject: any)=>{
    resolve();
  });

  // return null;
}

  /**
   * Use `paayes.confirmAcssDebitSetup` to [save details for future payments with pre-authorized debit in Canada](https://paayes.com/docs/payments/acss-debit/set-up-payment).
   * When called, it will automatically pop up a modal to collect bank account details and verification, accept the mandate, and confirm the [SetupIntent](https://paayes.com/docs/api/setup_intents) when the user submits the form.
   *
   * When you confirm a `SetupIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * `paayes.confirmAcssDebitSetup` automatically creates a new `PaymentMethod` for you when your customer completes the modal UI.
   * It can also be called with an existing `PaymentMethod` which will load the modal UI to collect a new mandate agreement.
   * If you have already attached a `PaymentMethod`, you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/setup_intents/confirm_acss_debit_setup
   */
  confirmAcssDebitSetup(
    clientSecret: string,
    data?: setupIntents.ConfirmAcssDebitSetupData,
    options?: setupIntents.ConfirmAcssDebitSetupOptions
  ): Promise<SetupIntentResult>{

    return null;
}

  /**
   * Requires beta access:
   * Contact [Paayes support](https://support.paayes.com/) for more information.
   *
   * Use `paayes.confirmAuBecsDebitSetup` in the [BECS Direct Debit with Setup Intents](https://paayes.com/docs/payments/payment-methods/au-becs-debit-quickstart-setup-intents) flow when the customer submits your payment form.
   * When called, it will confirm the `SetupIntent` with `data` you provide.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/payment-methods/au-becs-debit-quickstart-setup-intents) for more details.
   *
   * When you confirm a `SetupIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `SetupIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * It can also be called with an existing `PaymentMethod`, or if you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/setup_intents/confirm_au_becs_debit_setup
   */
  confirmAuBecsDebitSetup(
    clientSecret: string,
    data?: setupIntents.ConfirmAuBecsDebitSetupData
  ): Promise<SetupIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmBacsDebitSetup` in the [Bacs Direct Debit Payments](https://paayes.com/docs/payments/payment-methods/bacs-debit) flow when the customer submits your payment form.
   * When called, it will confirm the [SetupIntent](https://paayes.com/docs/api/setup_intents) with `data` you provide.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/payment-methods/bacs-debit) for more details.
   *
   * When you confirm a `SetupIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `SetupIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * It can also be called with an existing `PaymentMethod`, or if you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/setup_intents/confirm_bacs_debit_setup
   */
  confirmBacsDebitSetup(
    clientSecret: string,
    data?: setupIntents.ConfirmBacsDebitSetupData
  ): Promise<SetupIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmBancontactSetup` in the [Set up future payments](https://paayes.com/docs/payments/bancontact/set-up-payment) flow to use Bancontact bank details to set up a SEPA Direct Debit payment method for future payments.
   * When called, it will confirm a `SetupIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/bancontact/set-up-payment) for more details.
   *
   * When you confirm a `SetupIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `SetupIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * It can also be called with an existing `PaymentMethod`, or if you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_bancontact_setup
   */
  confirmBancontactSetup(
    clientSecret: string,
    data?: setupIntents.ConfirmBancontactSetupData
  ): Promise<SetupIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmCardSetup` in the [Setup Intents API flow](https://paayes.com/docs/payments/save-and-reuse) when the customer submits your payment form.
   * When called, it will confirm the [SetupIntent](https://paayes.com/docs/api/setup_intents) with `data` you provide and carry out 3DS or other next actions if they are required.
   *
   * When you confirm a `SetupIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `SetupIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * It can also be called with an existing `PaymentMethod`, or if you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/setup_intents/confirm_card_setup
   */
  confirmCardSetup(
    clientSecret: string,
    data?: setupIntents.ConfirmCardSetupData,
    options?: setupIntents.ConfirmCardSetupOptions
  ): Promise<SetupIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmIdealSetup` in the [Set up future payments](https://paayes.com/docs/payments/ideal/set-up-payment) flow to use iDEAL bank details to set up a SEPA Direct Debit payment method for future payments.
   * When called, it will confirm a `SetupIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/ideal/set-up-payment) for more details.
   *
   * When you confirm a `SetupIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `SetupIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * It can also be called with an existing `PaymentMethod`, or if you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/setup_intents/confirm_ideal_setup
   */
  confirmIdealSetup(
    clientSecret: string,
    data?: setupIntents.ConfirmIdealSetupData
  ): Promise<SetupIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmPayPalSetup` in the [Set up future payments](https://paayes.com/docs/payments/paypal/set-up-future-payments) flow when the customer submits your payment form.
   * When called, it will confirm a `SetupIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/paypal/set-up-future-payments) for more details.
   *
   * When you confirm a `SetupIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `SetupIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * It can also be called with an existing `PaymentMethod`, or if you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/setup_intents/confirm_paypal_setup
   */
  confirmPayPalSetup(
    clientSecret: string,
    data?: setupIntents.ConfirmPayPalSetupData
  ): Promise<SetupIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmSepaDebitSetup` in the [SEPA Direct Debit with Setup Intents](https://paayes.com/docs/payments/sepa-debit-setup-intents) flow when the customer submits your payment form.
   * When called, it will confirm the `SetupIntent` with `data` you provide.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/sepa-debit-setup-intents) for more details.
   *
   * When you confirm a `SetupIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `SetupIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * It can also be called with an existing `PaymentMethod`, or if you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   *
   * @docs https://paayes.com/docs/js/setup_intents/confirm_sepa_debit_setup
   */
  confirmSepaDebitSetup(
    clientSecret: string,
    data?: setupIntents.ConfirmSepaDebitSetupData
  ): Promise<SetupIntentResult>{

    return null;
}

  /*
   * Use `paayes.confirmSofortSetup` in the [Set up future payments](https://paayes.com/docs/payments/sofort/set-up-payment) flow to use SOFORT bank details to set up a SEPA Direct Debit payment method for future payments.
   * When called, it will confirm a `SetupIntent` with `data` you provide, and it will automatically redirect the customer to authorize the transaction.
   * Once authorization is complete, the customer will be redirected back to your specified `return_url`.
   * Note that there are some additional requirements to this flow that are not covered in this reference.
   * Refer to our [integration guide](https://paayes.com/docs/payments/sofort/set-up-payment) for more details.
   *
   * When you confirm a `SetupIntent`, it needs to have an attached [PaymentMethod](https://paayes.com/docs/api/payment_methods).
   * In addition to confirming the `SetupIntent`, this method can automatically create and attach a new `PaymentMethod` for you.
   * It can also be called with an existing `PaymentMethod`, or if you have already attached a `PaymentMethod` you can call this method without needing to provide any additional data.
   */
  confirmSofortSetup(
    clientSecret: string,
    data?: setupIntents.ConfirmSofortSetupData
  ): Promise<SetupIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmAffirmPayment` in the [Affirm payments](https://paayes.com/docs/payments/affirm) flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents).
   * Refer to our [integration guide](https://paayes.com/docs/payments/affirm/accept-a-payment) for more details.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_affirm_payment
   */

  confirmAffirmPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmAffirmPaymentData,
    options?: paymentIntents.ConfirmAffirmPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.confirmAfterpayClearpayPayment` in the [Afterpay / Clearpay payments](https://paayes.com/docs/payments/afterpay-clearpay) flow when the customer submits your payment form.
   * When called, it will confirm the [PaymentIntent](https://paayes.com/docs/api/payment_intents).
   * Refer to our [integration guide](https://paayes.com/docs/payments/afterpay-clearpay/accept-a-payment) for more details.
   *
   * @docs https://paayes.com/docs/js/payment_intents/confirm_afterpay_clearpay_payment
   */

  confirmAfterpayClearpayPayment(
    clientSecret: string,
    data?: paymentIntents.ConfirmAfterpayClearpayPaymentData,
    options?: paymentIntents.ConfirmAfterpayClearpayPaymentOptions
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Use `paayes.verifyMicrodepositsForSetup` in the [Save details for future payments with pre-authorized debit in Canada](https://paayes.com/docs/payments/acss-debit/set-up-payment) flow
   * to verify customer's bank account with micro-deposits.
   *
   * @docs https://paayes.com/docs/js/payment_intents/verify_microdeposits_for_setup
   */
  verifyMicrodepositsForSetup(
    clientSecret: string,
    data?: setupIntents.VerifyMicrodepositsForSetupData
  ): Promise<PaymentIntentResult>{

    return null;
}

  /**
   * Retrieve a [SetupIntent](https://paayes.com/docs/api/setup_intents) using its client secret.
   *
   * @docs https://paayes.com/docs/js/setup_intents/retrieve_setup_intent
   */
  retrieveSetupIntent(clientSecret: string): Promise<SetupIntentResult>{

    return null;
}

  /////////////////////////////
  /// Payment Request
  ///
  /// https://paayes.com/docs/js/payment_request
  /////////////////////////////

  /**
   * Use `paayes.paymentRequest` to create a `PaymentRequest` object.
   * Creating a `PaymentRequest` requires that you configure it with an `options` object.
   *
   * In Safari, `paayes.paymentRequest` uses Apple Pay, and in other browsers it uses the [Payment Request API standard](https://www.w3.org/TR/payment-request/).
   */
  paymentRequest(options: PaymentRequestOptions): PaymentRequest;

  /////////////////////////////
  /// Token and Sources
  ///
  /// https://paayes.com/docs/js/tokens_sources
  /////////////////////////////

  /**
   * Use `paayes.createToken` to convert information collected by an `IbanElement` into a single-use [Token](https://paayes.com/docs/api#tokens) that you safely pass to your server to use in an API call.
   *
   * @docs https://paayes.com/docs/js/tokens_sources/create_token?type=ibanElement
   */
  createToken(
    tokenType: elements.PaayesIbanElement | Element,
    data?: tokens.CreateTokenIbanData
  ): Promise<TokenResult>;

  /**
   * Use `paayes.createToken` to convert information collected by card elements into a single-use [Token](https://paayes.com/docs/api#tokens) that you safely pass to your server to use in an API call.
   *
   * @docs https://paayes.com/docs/js/tokens_sources/create_token?type=cardElement
   */
  createToken(
    tokenType: elements.PaayesCardElement | elements.PaayesCardNumberElement | Element,
    data?: tokens.CreateTokenCardData
  ): Promise<TokenResult>;

  /**
   * Use `paayes.createToken` to convert personally identifiable information (PII) into a single-use [Token](https://paayes.com/docs/api#tokens) for account identity verification.
   *
   * @docs https://paayes.com/docs/js/tokens_sources/create_token?type=pii
   */
  createToken(
    tokenType: 'pii',
    data: tokens.CreateTokenPiiData  | Element
  ): Promise<TokenResult>;

  /**
   * Use `paayes.createToken` to convert bank account information into a single-use token that you safely pass to your server to use in an API call.
   *
   * @docs https://paayes.com/docs/js/tokens_sources/create_token?type=bank_account
   */
  createToken(
    tokenType: 'bank_account',
    data: tokens.CreateTokenBankAccountData | Element
  ): Promise<TokenResult>;

  /**
     * Use `paayes.createToken` to tokenize the recollected CVC for a saved card.

     * First, render a `CardCvcElement` to collect the data.
     * Then pass the `CardCvcElement` to `paayes.createToken` to tokenize the collected data.
     *
     * @docs https://paayes.com/docs/js/tokens_sources/create_token?type=cvc_update
     */
  createToken(
    tokenType: 'cvc_update',
    element: elements.PaayesCardCvcElement
  ): Promise<TokenResult>;

  /**
   * Use `paayes.createToken` to create a single-use token that wraps a user’s legal entity information.
   * Use this when creating or updating a Connect account.
   * See the [account tokens documentation](https://paayes.com/docs/connect/account-tokens) to learn more.
   */
  createToken(
    tokenType: 'account',
    data: api.TokenCreateParams.Account
  ): Promise<TokenResult>;

  /**
   * Use `paayes.createToken` to create a single-use token that represents the details for a person.
   * Use this when creating or updating persons associated with a Connect account.
   * See the [documentation](https://paayes.com/docs/connect/account-tokens) to learn more.
   */
  createToken(
    tokenType: 'person',
    data: api.TokenCreateParams.Person
  ): Promise<TokenResult>;

  /**
   * Use `paayes.createSource` to convert payment information collected by elements into a `Source` object that you safely pass to your server to use in an API call.
   * See the [Sources documentation](https://paayes.com/docs/sources) for more information about sources.
   */
  createSource(
    element: PaayesElement | Element,
    sourceData: tokens.CreateSourceData
  ): Promise<SourceResult>;
  /**
   * Use `paayes.createSource` to convert raw payment information into a `Source` object that you safely pass to your server to use in an API call.
   * See the [Sources documentation](https://paayes.com/docs/sources) for more information about sources.
   */
  // createSource(sourceData: tokens.CreateSourceData): Promise<SourceResult>;
  // createSource(sourceData: tokens.CreateSourceData): Promise<SourceResult>{
  //   return new  Promise<SourceResult>(async (resolve,reject)=>{
  //   });
  // }

  /**
   * Retrieve a [Source](https://paayes.com/docs/api#sources) using its unique ID and client secret.
   *
   * @docs https://paayes.com/docs/js/tokens_sources/retrieve_source
   */
  retrieveSource(source: tokens.RetrieveSourceParam): Promise<never | SourceResult>;

  retrieveSource(source: tokens.RetrieveSourceParam | api.Source): Promise<never | SourceResult>{
    return new  Promise<any|SourceResult>(async (resolve,reject)=>{
      let result = null;
      if(source){
          try {
              result = await fetch(this.apiBaseUri+'/api/v1/sources/'+source.id+'?client_secret='+source.client_secret,{
                  method : 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization' : `Basic ${this.api_key}`
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  // body : JSON.stringify(source)
              })
              .then(res => {
                if (res.status>=200 && res.status<300) {
                  return res.json();
                } else {
                  // throw new Error("Not Found - retrieved source failed");
                  
                  try {
                    return res.json();
                  } catch (err) {
                    return {error:"Not Found - retrieved source failed"};
                  }
                  
                }
                
              })
              .then((srcresult:SourceResult) => {resolve(srcresult);console.log('in-sdk:success retrieved source: ', srcresult);})
              .catch((err) => {reject(err);console.log('in-sdk:fail retrieved source: ', err);});
              resolve(result);
              return result;
          } catch (error) {
            reject({error:"no data sent"});
              // throw "Error connection to server";
              return error;
              
          }
      } else {
        reject({error:"no data sent"});
          // throw "no data sent";
          // return result;
      }
    });
  }

  /////////////////////////////
  /// Analytics
  ///
  /////////////////////////////

  /**
   * Use `paayes.registerAppInfo` to register a frontend open source library.
   */
  registerAppInfo(wrapperLibrary: WrapperLibrary): void{

    // return null;
}

  /////////////////////////////
  /// Identity
  ///
  /////////////////////////////

  /**
   * Use `paayes.verifyIdentity` to display an [Identity](https://paayes.com/docs/identity) modal that securely collects verification information.
   *
   * * @docs https://paayes.com/docs/js/identity/modal
   */
  verifyIdentity(clientSecret: string): Promise<VerificationSessionResult>{

    return null;
}

}

global.Flospay  =  (api_token)=>{
    // alert("Hisham")
    return new Main(api_token,{apiVersion:'1.1.0'});
}




export type PaymentIntentResult =
  | {paymentIntent: api.PaymentIntent; error?: undefined}
  | {paymentIntent?: undefined; error: PaayesError};

export type SetupIntentResult =
  | {setupIntent: api.SetupIntent; error?: undefined}
  | {setupIntent?: undefined; error: PaayesError};

export type PaymentMethodResult =
  | {paymentMethod: api.PaymentMethod; error?: undefined}
  | {paymentMethod?: undefined; error: PaayesError};

export type SourceResult =
  | {source: api.Source; error?: undefined}
  | {source?: undefined; error: PaayesError};

export type TokenResult =
  | {token: api.Token; error?: undefined}
  | {token?: undefined; error: PaayesError};

export type VerificationSessionResult =
  | {verificationSession: api.VerificationSession; error?: undefined}
  | {verificationSession?: undefined; error: PaayesError};

export interface WrapperLibrary {
  /**
   * Your library’s name, maximum length is 30
   */
  name: string;

  /**
   * Required for Paayes Verified Partners, optional otherwise
   * Your Partner ID from the Partners section of the Dashboard
   */
  partner_id?: string;

  /**
   * Your library's version, in the format of x.x.x
   */
  version?: string;

  /**
   * The URL for your library's website with your contact details
   */
  url?: string;
}

/**
 * Use `Paayes(publishableKey, options?)` to create an instance of the `Paayes` object.
 * The Paayes object is your entrypoint to the rest of the Paayes.js SDK.
 *
 * Your Paayes publishable [API key](https://paayes.com/docs/keys) is required when calling this function, as it identifies your website to Paayes.
 *
 * When you’re ready to accept live payments, replace the test key with your live key in production.
 * Learn more about how API keys work in [test mode and live mode](https://paayes.com/docs/dashboard#viewing-test-data).
 */
export interface PaayesConstructor {
  (
    /**
     * Your publishable key.
     */
    publishableKey: string,

    /**
     * Initialization options.
     */
    options?: PaayesConstructorOptions
  ): Paayes;
}

export interface PaayesConstructorOptions {
  /**
   * For usage with [Connect](https://paayes.com/docs/connect) only.
   * Specifying a connected account ID (e.g., `acct_24BFMpJ1svR5A89k`) allows you to perform actions on behalf of that account.
   */
  paayesAccount?: string;

  /**
   * Override your account's [API version](https://paayes.com/docs/api/versioning).
   */
  apiVersion?: string;

  /**
   * The [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag) used to globally configure localization in Paayes.js.
   * Setting the locale here will localize error strings for all Paayes.js methods.
   * It will also configure the locale for [Elements](#element_mount) and [Checkout](https://paayes.com/docs/js/checkout/redirect_to_checkout). Default is `auto` (Paayes detects the locale of the browser).
   *
   * Supported values depend on which features you are using.
   * Checkout supports a slightly different set of locales than the rest of Paayes.js.
   * If you are planning on using Checkout, make sure to use a [value](#checkout_redirect_to_checkout-options-locale) that it supports.
   */
  locale?: PaayesElementLocale | CheckoutLocale;

  /**
   * Opt-in to prerelease Paayes.js features by passing `betas` when instantiating a `Paayes` object.
   *
   * Supported values for the `betas` option can be found in integration guides for prerelease features.
   * Most users of Paayes.js do not pass this option.
   */
  betas?: string[];
}

export type PaayesErrorType =
  /**
   * Failure to connect to Paayes's API.
   */
  | 'api_connection_error'

  /**
   * API errors cover any other type of problem (e.g., a temporary problem with Paayes's servers), and are extremely uncommon.
   */
  | 'api_error'

  /**
   * Failure to properly authenticate yourself in the request.
   */
  | 'authentication_error'

  /**
   * Card errors are the most common type of error you should expect to handle.
   * They result when the user enters a card that can't be charged for some reason.
   */
  | 'card_error'

  /**
   * Idempotency errors occur when an `Idempotency-Key` is re-used on a request that does not match the first request's API endpoint and parameters.
   */
  | 'idempotency_error'

  /**
   * Invalid request errors arise when your request has invalid parameters.
   */
  | 'invalid_request_error'

  /**
   * Too many requests hit the API too quickly.
   */
  | 'rate_limit_error'

  /**
   * Errors triggered by our client-side libraries when failing to validate fields (e.g., when a card number or expiration date is invalid or incomplete).
   */
  | 'validation_error';

export interface PaayesError {
  /**
   * The type of error.
   */
  type: PaayesErrorType;

  /**
   * For card errors, the ID of the failed charge
   */
  charge?: string;

  /**
   * For some errors that could be handled programmatically, a short string indicating the [error code](https://paayes.com/docs/error-codes) reported.
   */
  code?: string;

  /**
   * For card errors resulting from a card issuer decline, a short string indicating the [card issuer’s reason for the decline](https://paayes.com/docs/declines#issuer-declines) if they provide one.
   */
  decline_code?: string;

  /**
   * A URL to more information about the [error code](https://paayes.com/docs/error-codes) reported.
   */
  doc_url?: string;

  /**
   * A human-readable message providing more details about the error. For card errors, these messages can be shown to your users.
   */
  message?: string;

  /**
   * If the error is parameter-specific, the parameter related to the error.
   * For example, you can use this to display a message near the correct form field.
   */
  param?: string;

  /**
   * The `PaymentIntent` object for errors returned on a request involving a `PaymentIntent`.
   */
  payment_intent?: api.PaymentIntent;

  /**
   * The `PaymentMethod` object for errors returned on a request involving a `PaymentMethod`.
   */
  payment_method?: api.PaymentMethod;

  /**
   * The `SetupIntent` object for errors returned on a request involving a `SetupIntent`.
   */
  setup_intent?: api.SetupIntent;

  /**
   * The `Source` object for errors returned on a request involving a `Source`.
   */
  source?: api.Source;
}


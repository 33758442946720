import {JSEncrypt} from 'jsencrypt';
import {getPublicKey} from '../public_key';
export class CardOnChange {
    private encrypt : JSEncrypt;
    constructor(private event:string , 
                private api_key:string, 
                private callback:Function , 
                private element:HTMLIFrameElement,
                ){
                window.onload=()=>{
                    this.setPublicKey();
                }
                window.addEventListener('message' , (e)=>{
                    if ('string'==typeof e.data || 'undefined'==typeof e.data.data) {
                        return false;
                    }
        
                    let ev = null;
                    try {
                        ev  = JSON.parse(e.data.data);
                        
                        if(ev.event == 'onChange'){
                            // console.log('herereere')
                            console.log(this.callback);
                            this.callback(this.encrypt.encrypt(ev));

                         }
                    } catch (error) {
                        
                    }
                  
                } , false);
    }
    setPublicKey(){
        // console.log('Hisham')
        this.encrypt = new JSEncrypt({});

        this.encrypt.setPublicKey(getPublicKey());
        
    }
}
import { Styles } from '~Interfacies/Styles';
import { Card } from '~packages/Card';
export class ThreeDSecure{
    protected  element :  HTMLIFrameElement;
    protected api_key;
    protected type: string;
    protected selector: string;
    protected card_data : string | false;
    protected options : any;
    protected styles:Styles;
    protected defaultStyles:Styles;
    private url: string;
    private return_url: string;

    constructor(_api_key? :string,type?:string ,clientSecret?:string, data?:any, options?:any){
        this.api_key = _api_key;
        this.type = type??null;
    }

    create(card:Card , options?:any){
        // console.log(type);
        this.element  = this.createIframe();

        return this.element;
        
        return new ThreeDSecure();
    }
    mountElement(container:HTMLElement , options:any = null){
        // console.log(type);
        
        this.element  = this.createIframe();

        return this.element;
    }
    
    mount(selector:string, callback:Function){
        
        this.selector = selector;
        document.querySelector(selector).appendChild(this.element.getRootNode());
        const a :any = document.getElementById('ifrm3ds_a4ad2ee43db73d');

        a.onload = ()=>{
           window.onmessage  =  (ev)=>{
               try {
                    const da = JSON.parse(ev.data.data);
                    console.log(da.event);
                    callback(ev);
                    if(da.event == 'onValid'){
                        var data = ((JSON.stringify({
                            number  : da.card.number,
                            cvv  : da.card.cvc,
                            month  : da.card.expMonth,
                            year  : da.card.expYear,
                            postalCode  : da.card.postalCode,
                        })));
                        

                        const sd : string | false = this.encrypt.encrypt(data);
                        this.card_data = sd;


                        this.createInputHiddenElement(selector  ,sd);
                    
                    } else {
                        this.removeInputHiddenElement();
                    }
                    // console.log('EVVV' , da.event);
               } catch (error) {
                //    console.log('Hoso Moso',ev.data); 
               }
           }
        }
       

    }


    createIframe(){
        const element : HTMLIFrameElement = document.createElement("iframe");
        element.src = this.url;
        element.id = 'ifrm3ds_a4ad2ee43db73d';//e.now();
        element.frameBorder = "0";
        element.scrolling = "no";
        element.setAttribute('style' , 
            `border: none;
            height:${this.styles.height ||  this.styles.type == 'inline'? defaultStyles.height : (this.styles.type.toLowerCase() == 'twolines'?defaultStyles.twoLinesHeight:'87px')};
            !important; 
            margin: 0px !important; 
            padding: 0px !important; width: 1px !important; min-width: 100% !important; 
            overflow: hidden !important; display: block !important; user-select: none !important; will-change: transform !important;
            `);
        // element.allow =  "*";
        element.allowPaymentRequest = true;
        
        return element;
    }
    getData(){
        return this.card_data;
    }

    update(options){
        this.options = options;
    }
    
    createInputHiddenElement(selector , value=null){
        let cardInput : HTMLInputElement;
        console.log(selector+" #flospay-card");
        if(!!document.getElementById("flospay-card")){
            cardInput   = (document.getElementById('flospay-card')  as HTMLInputElement);
            cardInput.value = value;
            return ;
        }
        cardInput   = document.createElement('input');
        cardInput.type = 'hidden';
        cardInput.name = 'card';
        cardInput.id = 'flospay-card';
        cardInput.value = value;
        document.querySelector(selector).appendChild(cardInput.getRootNode());
        
    }

    removeInputHiddenElement(){

    }
}
import {JSEncrypt} from 'jsencrypt';
import {getPublicKey, getPrivateKey} from '../public_key';

export class CardOnValid {
    private encrypt : JSEncrypt;
    constructor(private event:string , 
                private api_key:string, 
                private callback:Function , 
                private element:HTMLIFrameElement,
                private selector:String
                ){
        window.onload=()=>{
            this.setPublicKey();
        }    
        window.addEventListener('message' , (e)=>{
            console.log('CardOnValid:',e,e.data);
            if ('string'==typeof e.data || 'undefined'==typeof e.data.data) {
                return false;
            }
            
                const ev = JSON.parse(e.data.data);
                if(ev.event == 'onValid'){
                    // console.log(ev);
                    var data = (btoa(JSON.stringify({
                        number  : ev.card.number,
                        cvc  : ev.card.cvc,
                        month  : ev.card.expMonth,
                        year  : ev.card.expYear,
                        postalCode  : ev.card.postalCode,
                    })));

                    var sd = this.encrypt.encrypt(data);
                    // console.log(sd);
                    // /setTimeout(() => {
                        // console.log(this.encrypt.decrypt(sd));
                    
                    
                    this.callback(sd);
                        
                    // }, 2000);
            }
        } , false);
    }
    setPublicKey(){
        // console.log('Hisham')
        this.encrypt = new JSEncrypt({
            // log:true,
        });
        // this.encrypt.setKey();
        this.encrypt.setPublicKey(getPublicKey());
        this.encrypt.setPrivateKey(getPrivateKey());
        
    }
}
import {JSEncrypt} from 'jsencrypt';
import {getPublicKey} from '../public_key';
export class CardOnInValid {
    private encrypt : JSEncrypt;
    constructor(private event:string , private api_key:string, private callback:Function , private element:HTMLIFrameElement){
        window.onload=()=>{
            this.setPublicKey();
        }    
            window.addEventListener('message' , (e)=>{
                if ('string'==typeof e.data || 'undefined'==typeof e.data.data) {
                    return false;
                }
    
                const ev = JSON.parse(e.data.data);
                if(ev.event == 'onInValid'){
                    
                    this.callback({
                        error : 'Card is not valid'
                    });
            }
        } , false);
    }

    setPublicKey(){
        console.log('Hisham')
        this.encrypt = new JSEncrypt({
            // log:true,
        });
        // this.encrypt.setKey();
        this.encrypt.setPublicKey(getPublicKey());
        
    }
}
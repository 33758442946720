import { Styles } from '~Interfacies/Styles';
import { IElement } from '~Interfacies/IElement';
import { PaayesCardElement, PaayesElement, PaayesElementBase } from '~types/paayes-js';
export class Element  implements IElement {
    protected  element :  HTMLIFrameElement;
    protected api_key;
    protected type: string;
    protected card_data : string | false;
    protected options : any;
    protected styles:Styles =  null;
    protected defaultStyles:Styles =  null;

    constructor(_api_key :string,type:string , styles:Styles = null){
        this.api_key = _api_key;
        this.styles = styles;
        this.type = type;

    }
    createElement(type:string , styles:Styles = null){
        // console.log(type);
        this.type = type;
        
        return this.element;
    }
    getData(){
        return this.card_data;
    }

    getType(){
        return this.type;
    }

    update(options){
        this.options = options;
    }
    
}
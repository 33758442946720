import {Card} from './Card';
import {CardNumberElement} from './CardNumberElement';
import {CardExpiryElement} from './CardExpiryElement';
import {CardCvcElement} from './CardCvcElement';
import {CardNameElement} from './CardNameElement';
import { Styles } from '~Interfacies/Styles';
export class Elements{
    private element: Element| Card ;  
    private api_key;
    public type: string;
    public card: Card;
    public bank: string;
    public paypal: string;
    public clientSecret: string;
    public options: any;
    constructor(_api_key :string){
        this.api_key = _api_key;
    }

    /**
     * This method creates an instance of an individual Element.
     * @param type The type of element you are creating.  'card', 'cardNumber', 'cardExpiry', 'cardCvc', 'Bank'
     * @param styles Options for creating a card element.
     * @returns 
     */
    create(type:string , options:ElementOptions = null){
        // console.log(type);
        const styles = options?options.style:null;
        this.type = type;
        if (type=='card' || type=='cardElement') {
            this.card = new Card(this.api_key , options);
            this.element = this.card;
    
        } else if (type=='cardNumber' || type=='cardNumberElement') {
            this.card = new CardNumberElement(this.api_key , options);
            this.element = this.card;
    
        } else if (type=='cardExpiry' || type=='cardExpiryElement') {
            this.card = new CardExpiryElement(this.api_key , options);
            this.element = this.card;
    
        } else if (type=='cardCvc' || type=='cardCvcElement') {
            this.card = new CardCvcElement(this.api_key , options);
            this.element = this.card;
    
        } else if (type=='cardName' || type=='cardNameElement') {
            this.card = new CardNameElement(this.api_key , options);
            this.element = this.card;
        }
        return this.element;
    }

    /**
     * This method looks up a previously created Element by its type.
     * @param type The type of Element to lookup.
     * @returns Element | null
     */
    getElement(type:string){
    
        if (type=='card') {
            return this.card;
        } else if (type=='cardNumber') {
            return this.card.cardNumber;
        } else if (type=='cardCvc') {
            return this.card.cardCvc;
        } else if (type=='cardExpiry') {
            return this.card.cardExpiry;
        } else if (type=='CardName') {
            return this.card.cardName;
        } else if (type=='CardPostalcode') {
            return this.card.cardPostalcode;
        } else {
            
        }
        return this.card;
        // return this.element as Card;
    }

    update(options:ElementOptions){
        this.options = options;
    }
    
}
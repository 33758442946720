export  function getPublicKey(){
    return `-----BEGIN PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCu7IK5rG7VAtLXwLWHoZhfQkPm
    jazJF97LGh/iseVxFRGE7pOdt6VRjA0mehgncGlK+3IDuGRE2W6jpXrbGEbEvaTS
    bKylxIJrQxNxkr1OGVwpeY1L2bpLIXLQJ9CMeCzS33kZklE2HMp9tVcjHi1H977b
    KK5bra4AGdtprffk/QIDAQAB
    -----END PUBLIC KEY-----`;
    return `-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCfnX2PEkrSSrlgt4t4sUin88V1rhzx/oOZy03/qOA5AA3G6Esh3gK8/tDUqCtoLUYkhrG/qwD5mexjZw70603onpRm/A5EXAsjfJXHZqawxI0I1kJ3T4URyXnZh28hnlAZlizJBYuncEYOLyRDfPn3iWQaKM04i0EBsYf5aiFMVwIDAQAB-----END PUBLIC KEY-----`;
}

export function getPrivateKey(){
    return `-----BEGIN PRIVATE KEY-----
    MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAK7sgrmsbtUC0tfA
    tYehmF9CQ+aNrMkX3ssaH+Kx5XEVEYTuk523pVGMDSZ6GCdwaUr7cgO4ZETZbqOl
    etsYRsS9pNJsrKXEgmtDE3GSvU4ZXCl5jUvZukshctAn0Ix4LNLfeRmSUTYcyn21
    VyMeLUf3vtsorlutrgAZ22mt9+T9AgMBAAECgYAXw/pez5afSirrNTt0mffhuVcQ
    U99N0vhqD7qVSiYqHvqqXN+hZfoIAkcnGx98/EDR9yeeacb1s7TcdvciHUI/7wfP
    PocjuyQdPJ+7Pm51B1dtlUUPH4rlieSBKdmzO4XThtil0LOb1jQ9IfvgofgTdh91
    BQ8gHaJoidxKhIXK4QJBAOENBL1hbftYDWs4Ze5/S6tJlRgzzWco4B8e5jzKXOk+
    lkjNUbHHwcKFuSaSRnMh/7nxK96paelWFFMUWg07wLMCQQDG+sHFprJQpS41yVat
    ZMgSK70v0lo0LXKxE2xhZX5NHhsaWJ0Of28Cog42L72tyKdJczNqGfTZqWWO+f9G
    GjuPAkAb72jCNUDYrTJlN8f3VdfHuEeIjI2Xm4xOcKB1tUzZKwqumMXmQnx7r+uu
    rQOPsQ20QAqJBhB+hU/ajDEtaVhfAkA6YcbLFG8LZHvbgjixZXmigzzeNC0NVCxr
    slRBe4MEY4n2P/vNDiznK99ZnMZdoBsdXPS/89+4oZBP1CBHVFkXAkEAy7FjC/dD
    COsd6C8y6iP81RoN/hIWEU6+KnHhbCZUU/HIxDFSQnoRAWh7BrxaUgmHEgxLEnoY
    +2xlC/iGnPvLZQ==
    -----END PRIVATE KEY-----`;
}